import fetchAdapter from './utils/fetch-adapter';
import { generateNewAuthDomain } from './utils';
import { CLIENT_IP_URL } from './const';

export const defineSsoConfig = async ({ origin }) => {
  const fetchIso = async () => {
    // eslint-disable-next-line camelcase
    const { iso_code: isoCode, client_ip: clientIp } = await fetchAdapter({ url: CLIENT_IP_URL });
    return { isoCode, clientIp };
  };

  try {
    const { isoCode, clientIp } = await fetchIso();
    const newAuthDomain = generateNewAuthDomain({ iso: isoCode, origin });

    return {
      SSO: `${newAuthDomain}/api/v1/authorize`,
      API: `${newAuthDomain}/api`,
      CLIENT_IP: clientIp,
    };
  } catch (e) {
    console.error(e);
    return e;
  }
};
