export class PhoneOrder {
  constructor({ orderId, codeSentAt, isMobile, attempts }) {
    Object.assign(
      this,
      {
        orderId,
        codeSentAt: Date.parse(codeSentAt),
        isMobile,
        attempts,
      },
    );
  }
}
