import { CURRENT_GOLD_MAX_EQUITY, DAYS_COUNT_FOR_GOLD_PERIOD, LOYALTY_STATUSES } from '@/constants';
import type { RawProfile } from '@fsd/shared/api';
import type { PersonalManager } from '@fsd/shared/types';
import type { Profile } from '../../types';

export const profileMapper = (profile: RawProfile): Profile => {
  const {
    id,
    attributes: {
      countryName,
      createdAt,
      email,
      fullName,
      loyaltyStatus,
      goldVolume,
      goldEquity,
      goldDateOfAssignment,
      goldShow,
      goldMaxVolume,
      personalManager,
      officeLocale,
      officeSignedUpAt,
      officeHasPayments,
      officeFirstPaymentAt,
      officeHasTrades,
      officeCountTrade,
      officeUserCounter,
      officeHasVerified,
      officeHasWithdrawals,
      officeWithdrawalsCount,
      officeBonusApplicable,
      officeTotalLocked,
      officeReferAFriend,
      settings: {
        showBalanceInTitle,
        showPromoBanner,
        latestTimeSuccessVerification,
        showGoldUpdatesBanner,
        showOnboarding,
        showPremiumAnalytics,
      },
      additionalVerification: {
        required,
        url,
      },
    },
  } = profile;

  const getGoldStatusDaysLeft = (): null | number => {
    if (!goldDateOfAssignment) return null;

    const MILLISECONDS_IN_DAY = 24 * 60 * 60 * 1000;
    const goldAssignmentDate = new Date(goldDateOfAssignment);
    const factualDifference = Math.floor((Number(new Date()) - Number(goldAssignmentDate)) / MILLISECONDS_IN_DAY);
    const displayedDifference = DAYS_COUNT_FOR_GOLD_PERIOD - factualDifference;

    return displayedDifference >= 0 ? displayedDifference : 0;
  };

  const getPersonalManager = (): null | PersonalManager => {
    if (!personalManager) return null;

    const isPersonalManager = Object.values(personalManager).some((value) => value !== null);
    return isPersonalManager ? personalManager : null;
  };

  return {
    id,
    goldMaxEquity: CURRENT_GOLD_MAX_EQUITY,
    isGoldShown: goldShow,
    isGoldStatus: loyaltyStatus === LOYALTY_STATUSES.GOLD && goldShow,
    goldStatusDaysLeft: getGoldStatusDaysLeft(),
    personalManager: getPersonalManager(),
    countryName,
    createdAt,
    email,
    fullName,
    loyaltyStatus,
    goldVolume,
    goldEquity,
    goldDateOfAssignment,
    goldMaxVolume,
    officeLocale,
    officeSignedUpAt,
    officeHasPayments,
    officeFirstPaymentAt,
    officeHasTrades,
    officeCountTrade,
    officeUserCounter,
    officeHasVerified,
    officeHasWithdrawals,
    officeWithdrawalsCount,
    officeBonusApplicable,
    officeTotalLocked,
    officeReferAFriend,
    locale: officeLocale,
    isBalanceInTitleShown: showBalanceInTitle,
    isPromoBannerShown: showPromoBanner,
    isGoldUpdateBannerShown: showGoldUpdatesBanner,
    isOnboardingBannerShown: showOnboarding,
    isPremiumAnalyticsShown: showPremiumAnalytics,
    isAdditionalVerificationRequired: required,
    additionalVerificationUrl: url,
    latestDisplayTimeSuccessVerificationModal: latestTimeSuccessVerification,
  };
};
