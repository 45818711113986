import { LOCALES } from '@/constants';

export const createTranslationsByLocale = (supportedLocales = LOCALES) => {
  if (!supportedLocales) throw new Error('Locales must be provided');

  const translationsByLocales = {};
  Object.values(supportedLocales).forEach((locale) => {
    translationsByLocales[locale] = () => import(`@amarkets-web/front-office-locales/translations/${locale}.json`);
  });

  return translationsByLocales;
};
