import { get } from '@amarkets/atlas/esm/lib/get';
import { isArray } from '@amarkets/atlas/esm/lib/is-array';
import { HTTP_ERRORS } from '@/constants';

const MUTATION_SET_MESSAGE_ERROR = 'MUTATION_SET_MESSAGE_ERROR';
const ERRORS = [
  HTTP_ERRORS.BUSINESS_LOGIC_ERROR,
  HTTP_ERRORS.FORBIDDEN,
];

export const handleError = (httpErrorParser, errorHandler) => ({
  error,
  commit,
  customErrMsg,
  statusCodesForCustomBehavior,
}) => {
  const { statusCode, msg } = httpErrorParser.parse({
    error,
    customParser: (e) => {
      const errorsData = get(e, 'response.data.errors');
      const isArrayErrorsData = isArray(errorsData);

      return isArrayErrorsData ? errorsData[0]?.detail : errorsData;
    },
  });
  const hasBusinessLogicError = () => statusCode && ERRORS.includes(statusCode);
  const hasStatusCodesForCustomBehavior = () => statusCodesForCustomBehavior
    && statusCodesForCustomBehavior.includes(statusCode);

  if (hasBusinessLogicError() || hasStatusCodesForCustomBehavior()) {
    // TODO: https://amarkets.atlassian.net/browse/PA-281
    commit(MUTATION_SET_MESSAGE_ERROR, msg);
  } else {
    errorHandler.handle(error, customErrMsg);
  }
};
