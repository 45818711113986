import { Currency } from '@amarkets/atlas/esm/lib/currency';
import {
  TERMINAL_PLATFORMS_NAMES,
  TRADING_ACCOUNT_TYPES,
  WEB_TERMINALS_LINKS,
  TERMINAL_PLATFORMS_VARIABLES,
} from '@/constants';

export class AgoraUserProfile {
  constructor({
    id,
    uid,
    login,
    leverage,
    credit,
    platform,
    type,
    equity,
    currency,
    dailyPnl,
    margin: {
      value,
      free,
      progress,
    },
    isEcnPromo,
  }) {
    Object.assign(this, {
      id,
      uid,
      login,
      leverage,
      currency,
      bonusAmount: credit,
      balance: AgoraUserProfile._getNumberWithCurrency(equity, currency),
      margin: AgoraUserProfile._getNumberWithCurrency(value, currency),
      dailyPnl: AgoraUserProfile._getNumberWithCurrency(dailyPnl, currency),
      freeMargin: AgoraUserProfile._getNumberWithCurrency(free, currency),
      allMarginProgress: progress,
      platformHumanName: platform.humanName,
      platformName: platform.name,
      type,
      typeWithPlatformName: AgoraUserProfile._getTypeWithPlatformName(type, platform.name),
      webTerminalLink: WEB_TERMINALS_LINKS[platform.name] ? `/mt-web/${AgoraUserProfile._getTerminalLink(platform.name)}` : null,
      isEcnDiscountActive: isEcnPromo,
    });
  }

  static _getTerminalLink(name) {
    return new Map([
      [WEB_TERMINALS_LINKS.mt, TERMINAL_PLATFORMS_VARIABLES.MT4],
      [WEB_TERMINALS_LINKS.mt5, TERMINAL_PLATFORMS_VARIABLES.MT5],
      [WEB_TERMINALS_LINKS.mt5Demo, TERMINAL_PLATFORMS_VARIABLES.MT5_DEMO],
    ]).get(WEB_TERMINALS_LINKS[name]);
  }

  static _getNumberWithCurrency(number, currency) {
    return new Currency({
      value: number,
      symbol: currency,
      precision: 2,
    }).currency.format();
  }

  static _getTypeWithPlatformName(type, platformName) {
    return type === TRADING_ACCOUNT_TYPES.MASTER ? TRADING_ACCOUNT_TYPES.MASTER_DISPLAY_NAME : `${type} ${TERMINAL_PLATFORMS_NAMES[platformName]}`;
  }

  get isMasterAccount() {
    return this.type === TRADING_ACCOUNT_TYPES.MASTER;
  }
}
