import { Currency } from '@amarkets/atlas/esm/lib/currency';
import {
  DEPOSIT_ACCOUNT_TYPES,
  DEPOSIT_ACCOUNT_STATUSES,
  TRADING_ACCOUNT_TYPES,
} from '@/constants/index';

export class AccountItem {
  constructor(payload) {
    const {
      type,
      balance,
      bonus,
      currency,
      limits,
      platform,
    } = payload;
    const isWallet = type === DEPOSIT_ACCOUNT_TYPES.WALLET;
    const isAccount = type === DEPOSIT_ACCOUNT_TYPES.ACCOUNT;
    const isCopyTradingAccount = isAccount && payload.accountType === TRADING_ACCOUNT_TYPES.MASTER;
    const accountType = isCopyTradingAccount
      ? TRADING_ACCOUNT_TYPES.MASTER_DISPLAY_NAME
      : payload.accountType;
    const platformName = platform?.replaceAll(/[a-z ]/g, '');
    const fullAccountName = platform && !isCopyTradingAccount
      ? `${accountType} ${platformName}`
      : accountType;

    Object.assign(this, {
      id: isWallet ? payload.id : undefined,
      uuid: isAccount ? payload.uuid : undefined,
      login: isAccount ? payload.login : undefined,
      type,
      status: isWallet ? DEPOSIT_ACCOUNT_STATUSES.ACTIVE : payload.status,
      isWallet,
      isAccount,
      name: isWallet
        ? 'depositPage.wallet'
        : fullAccountName,
      rawName: isWallet ? 'wallet' : payload.accountType,
      balance: balance !== null && currency
        ? new Currency({
          value: balance,
          symbol: currency,
          precision: 2,
        }).currency.format()
        : '',
      bonus: Number(bonus) && currency
        ? new Currency({
          value: bonus,
          symbol: currency,
          precision: 2,
        }).currency.format()
        : '',
      currency,
      limits,
    });
  }
}
