import { Action } from '@amarkets/atlas/esm/lib/action';
import {
  MUTATION_SET_MAIN_CHANNEL_SOCKET_MESSAGES,
  MUTATION_SET_WITHDRAWAl_PROCESSING_DATA,
} from '../types';
import { CENTRIFUGE_EVENT_TYPES } from '@/constants/index.js';
import { WithdrawalProcessingData } from '../entities/withdrawal-processing-data.js';
import { WithdrawalProcessingDataMapper } from '../mappers/withdrawal-processing-data-mapper.js';

export class AddMainChannelSocketMessage extends Action {
  // eslint-disable-next-line class-methods-use-this
  execute({ state, commit }, message) {
    const messages = [...state.mainChannelSocketMessages];
    const messageUuid = message.uuid;

    messages.push(message);
    commit(MUTATION_SET_MAIN_CHANNEL_SOCKET_MESSAGES, messages);

    // eslint-disable-next-line no-console
    console.log({ message });
    if (message.type === CENTRIFUGE_EVENT_TYPES.WITHDRAWAL_AUTOPROCESS_STARTED) {
      const withdrawalProcessingDataMapper = new WithdrawalProcessingDataMapper(
        (opts) => new WithdrawalProcessingData(opts));
      // eslint-disable-next-line no-console
      console.log(withdrawalProcessingDataMapper.mapWithdrawalProcessingData(message));
      commit(
        MUTATION_SET_WITHDRAWAl_PROCESSING_DATA,
        withdrawalProcessingDataMapper.mapWithdrawalProcessingData(message),
      );
    }

    const timeout = setTimeout(() => {
      const updatedMessages = [...state.mainChannelSocketMessages];
      const removingMessageIndex = updatedMessages.findIndex((m) => (
        m.uuid === messageUuid
      ));

      if (removingMessageIndex === -1) {
        return;
      }

      updatedMessages.splice(removingMessageIndex, 1);
      commit(MUTATION_SET_MAIN_CHANNEL_SOCKET_MESSAGES, updatedMessages);
      clearTimeout(timeout);
    }, 500);
  }
}
