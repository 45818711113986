import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { errorHandler, localeStorageLib } from '@fsd/shared/lib';
import { baseReqInterceptors, baseRespInterceptors, useInterceptors } from './lib';

interface HttpClientConfig {
  baseUrl: string;
  timeout?: number;
  requestInterceptors?: Array<{
    onFulfilled: (config: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>;
    onRejected?: (error: AxiosError) => AxiosError | Promise<AxiosError>;
  }>;
  responseInterceptors?: Array<{
    onFulfilled: (response: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>;
    onRejected?: (error: AxiosError) => AxiosError | Promise<AxiosError>;
  }>;
}

const { getItem } = localeStorageLib.useLocaleStorage();

export const useHttpClient = (config: HttpClientConfig) => {
  const axiosInstance: AxiosInstance = axios.create({
    baseURL: config.baseUrl,
    timeout: config.timeout || 10000,
  });

  axiosInstance.interceptors.request.use(
    (requestConfig) => {
      const newConfig = requestConfig;
      const token = getItem('accessToken');
      newConfig.headers = newConfig.headers ?? {};
      newConfig.headers.Authorization = `Bearer ${token}`;

      return newConfig;
    },
  );

  axiosInstance.interceptors.request.use(
    useInterceptors([...baseReqInterceptors.success]),
    useInterceptors([...baseReqInterceptors.error]),
  );

  axiosInstance.interceptors.response.use(
    useInterceptors([...baseRespInterceptors.success]),
    useInterceptors([...baseRespInterceptors.error]),
  );

  if (config.requestInterceptors) {
    config.requestInterceptors.forEach((interceptor) => {
      axiosInstance.interceptors.request.use(interceptor.onFulfilled, interceptor.onRejected);
    });
  }

  if (config.responseInterceptors) {
    config.responseInterceptors.forEach((interceptor) => {
      axiosInstance.interceptors.response.use(interceptor.onFulfilled, interceptor.onRejected);
    });
  }

  axiosInstance.interceptors.response.use(
    (response) => response,
    errorHandler,
  );

  const get = async <T>(url: string, options?: AxiosRequestConfig): Promise<T> => {
    const response = await axiosInstance.get<T>(url, options);
    return response.data;
  };

  const post = async <T>(url: string, data?: unknown, options?: AxiosRequestConfig): Promise<T> => {
    const response = await axiosInstance.post<T>(url, data, options);
    return response.data;
  };

  const put = async <T>(url: string, data: unknown, options?: AxiosRequestConfig): Promise<T> => {
    const response = await axiosInstance.put<T>(url, data, options);
    return response.data;
  };

  const patch = async <T>(url: string, data: unknown, options?: AxiosRequestConfig): Promise<T> => {
    const response = await axiosInstance.patch<T>(url, data, options);
    return response.data;
  };

  const del = async <T>(url: string, options?: AxiosRequestConfig): Promise<T> => {
    const response = await axiosInstance.delete<T>(url, options);
    return response.data;
  };

  return {
    get,
    post,
    put,
    patch,
    delete: del,
  };
};
