import { Currency } from '@amarkets/atlas/esm/lib/currency';
import {
  TERMINAL_PLATFORMS_NAMES,
  TRADING_ACCOUNT_TYPES,
  WEB_TERMINALS_LINKS,
  TERMINAL_PLATFORMS_VARIABLES,
} from '@/constants';

export class TradingAccount {
  constructor({
    id,
    attributes: {
      accountType,
      number,
      type,
      balance,
      equity,
      equityInUsd,
      platform,
      login,
      bonus,
      leverage,
      isEcnPromo,
      internalId,
    },
  }) {
    Object.assign(this, {
      id,
      accountType,
      number,
      type,
      login,
      leverage,
      platformHumanName: platform.human_name,
      platformName: platform.name,
      typeWithPlatformName: TradingAccount._getTypeWithPlatformName(type, platform.name),
      webTerminalLink: WEB_TERMINALS_LINKS[platform.name] ? `/mt-web/${TradingAccount._getTerminalLink(platform.name)}` : null,
      currency: balance.currency,
      balance: new Currency({
        value: equity,
        symbol: balance.currency,
        precision: 2,
      }).currency.format(),
      balanceAmount: balance.amount,
      equityInUsd,
      bonus: new Currency({
        value: bonus,
        symbol: balance.currency,
        precision: 2,
      }).currency.format(),
      bonusAmount: bonus,
      isEcnDiscountActive: isEcnPromo,
      internalId,
    });
  }
  static _getTerminalLink(name) {
    return new Map([
      [WEB_TERMINALS_LINKS.mt, TERMINAL_PLATFORMS_VARIABLES.MT4],
      [WEB_TERMINALS_LINKS.mt5, TERMINAL_PLATFORMS_VARIABLES.MT5],
      [WEB_TERMINALS_LINKS.mt5Demo, TERMINAL_PLATFORMS_VARIABLES.MT5_DEMO],
    ]).get(WEB_TERMINALS_LINKS[name]);
  }

  static _getTypeWithPlatformName(type, platformName) {
    return type === TRADING_ACCOUNT_TYPES.MASTER ? TRADING_ACCOUNT_TYPES.MASTER_DISPLAY_NAME : `${type} ${TERMINAL_PLATFORMS_NAMES[platformName]}`;
  }

  get isMasterAccount() {
    return this.type === TRADING_ACCOUNT_TYPES.MASTER;
  }
}
