import { Mapper } from '@amarkets/atlas/esm/lib/mapper';

export class WithdrawalProcessingDataMapper extends Mapper {
  _withdrawalProcessingData;

  constructor(withdrawalProcessingData) {
    super();

    this._withdrawalProcessingData = withdrawalProcessingData;
  }

  mapWithdrawalProcessingData = (response) => WithdrawalProcessingDataMapper
    ._getResult(response, this._withdrawalProcessingData);
}
