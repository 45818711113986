import { RouteRecordRaw } from 'vue-router';
import { ROUTES } from '@/constants';

export const FCouponsRoutes: RouteRecordRaw[] = [
  {
    name: ROUTES.FCOUPON.NAME,
    path: ROUTES.FCOUPON.PATH,
    component: () => import('./index.vue'),
    meta: {
      title: 'fcoupons.title',
    },
  },
];
