import { Action } from '@amarkets/atlas/esm/lib/action';
import {
  MUTATION_SET_ATTEMPTS,
  MUTATION_SET_CODE_SENT_AT,
  MUTATION_SET_IS_LOADING,
  MUTATION_SET_VERIFICATION_METHOD,
} from '../types';
import { VERIFICATION_METHODS } from '../../../const';
import { ConfirmationCodeMapper } from '../../../mappers/confirmation-code-mapper';
import { ConfirmationCode } from '../../../entities/confirmation-code';

export class SendConfirmationCode extends Action {
  _api;
  _uiNotifier;
  _i18n;
  _handleError;

  constructor({
    identifyApi,
    uiNotifier,
    i18n,
    handleError,
  }) {
    super();

    this._api = identifyApi;
    this._uiNotifier = uiNotifier;
    this._i18n = i18n;
    this._handleError = handleError;
  }

  async execute({ commit }, { orderId } = {}) {
    await SendConfirmationCode._withLoading(async () => {
      try {
        const confirmationCodeMapper = new ConfirmationCodeMapper((opts) => new ConfirmationCode(opts));

        const { attempts, codeSentAt, otpDeliveryMethod } = await this._api.sendConfirmationCode(
          { orderId },
          confirmationCodeMapper.mapConfirmationCode,
        );

        commit(MUTATION_SET_ATTEMPTS, { attempts });
        commit(MUTATION_SET_CODE_SENT_AT, { codeSentAt });
        commit(MUTATION_SET_VERIFICATION_METHOD, otpDeliveryMethod);

        if (!otpDeliveryMethod.includes(VERIFICATION_METHODS.CALL)) {
          this._uiNotifier.success(this._i18n.global.t('notification.success.sentСonfirmationCode'));
        }
      } catch (error) {
        this._handleError({
          error,
          commit,
          customErrMsg: 'while execute SendConfirmationCode',
        });
      }
    }, commit, MUTATION_SET_IS_LOADING);
  }
}
