import { useNotification } from '@kyvg/vue3-notification';

const { notify } = useNotification();

const notifyWithType = (type: 'success' | 'info' | 'warn' | 'error', message: string) => {
  notify({
    type,
    text: message,
  });
};

export const success = (message: string) => notifyWithType('success', message);
export const info = (message: string) => notifyWithType('info', message);
export const warn = (message: string) => notifyWithType('warn', message);
export const error = (message: string) => notifyWithType('error', message);
