import { RouteRecordRaw } from 'vue-router';
import { SERVICES_ROUTES } from '@fsd/shared/constants/routes';

export const TradingPlanRoutes: RouteRecordRaw[] = [
  {
    name: SERVICES_ROUTES.TRADING_PLAN.NAME,
    path: SERVICES_ROUTES.TRADING_PLAN.PATH,
    component: () => import('./page-trading-plan.vue'),
    meta: {
      title: 'pageTitle.tradingPlan',
    },
  },
];
