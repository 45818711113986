import {
  MUTATION_SET_USER_DATA,
  MUTATION_SET_USER_DATA_LOADING,
  MUTATION_SET_CURRENT_USER_ROLE,
  MUTATION_SET_USER_ROLES,
  MUTATION_SET_ACCOUNTS_DATA_LOADING,
  MUTATION_SET_ACCOUNTS_DATA,
  MUTATION_SET_PARTNER_DATA,
  MUTATION_SET_PARTNER_DATA_LOADING,
  MUTATION_SET_USER_PROFILE,
  MUTATION_SET_IS_USER_PROFILE_LOADING,
  MUTATION_SET_ARCHIVED_TRADING_ACCOUNTS_LOADING,
  MUTATION_SET_ARCHIVED_TRADING_ACCOUNTS,
  MUTATION_SET_IS_TRADES_LOADING,
  MUTATION_SET_TRADES,
  MUTATION_SET_TRADES_TOTAL,
  MUTATION_ADD_TRADES,
  MUTATION_SET_TRADES_PAGES_COUNT,
  MUTATION_SET_ECN_PROMO,
  MUTATION_SET_IS_ECN_PROMO_LOADING,
  MUTATION_SET_IS_ENABLE_ECN_PROMO_LOADING,
  MUTATION_SET_APPLICATION_TITLE,
  MUTATION_SET_ACTIVE_ACCOUNT,
  MUTATION_SET_IS_OPEN_SUCCESS_VERIFICATION_MODAL,
  MUTATION_SET_WALLET_ID,
  MUTATION_SET_USER_COUNTRY,
  MUTATION_SET_MAIN_CHANNEL_SOCKET_MESSAGES,
  MUTATION_SET_AGORA_USER_PROFILE,
  MUTATION_SET_AGORA_USER_PROFILE_LOADING,
  MUTATION_SET_WITHDRAWAl_PROCESSING_DATA,
  MUTATION_SET_USER_IP,
} from './types';

export default {
  [MUTATION_SET_USER_DATA](state, userData) {
    state.user = {
      ...state.user,
      ...userData,
    };
  },
  [MUTATION_SET_WALLET_ID](state, walletId) {
    state.walletId = walletId;
  },
  [MUTATION_SET_USER_COUNTRY](state, country) {
    state.userCountry = country;
  },
  [MUTATION_SET_USER_DATA_LOADING](state, isLoading) {
    state.isUserDataLoading = isLoading;
  },
  [MUTATION_SET_CURRENT_USER_ROLE](state, currentUserRole) {
    state.user = {
      ...state.user,
      currentUserRole,
    };
  },
  [MUTATION_SET_USER_ROLES](state, roles) {
    state.user = {
      ...state.user,
      roles,
    };
  },
  [MUTATION_SET_PARTNER_DATA](state, partnerData) {
    state.partner = {
      ...state.partner,
      ...partnerData,
    };
  },
  [MUTATION_SET_PARTNER_DATA_LOADING](state, isLoading) {
    state.isPartnerDataLoading = isLoading;
  },
  [MUTATION_SET_ACCOUNTS_DATA_LOADING](state, isLoading) {
    state.isAccountsDataLoading = isLoading;
  },
  [MUTATION_SET_ACCOUNTS_DATA](state, payload) {
    state.accounts = payload;
  },
  [MUTATION_SET_USER_PROFILE](state, userProfile) {
    state.userProfile = userProfile;
  },
  [MUTATION_SET_IS_USER_PROFILE_LOADING](state, isLoading) {
    state.isUserProfileLoading = isLoading;
  },
  [MUTATION_SET_ARCHIVED_TRADING_ACCOUNTS_LOADING](state, isLoading) {
    state.isArchivedTradingAccountsLoading = isLoading;
  },
  [MUTATION_SET_ARCHIVED_TRADING_ACCOUNTS](state, archivedTradingAccounts) {
    state.archivedTradingAccounts = archivedTradingAccounts;
  },
  [MUTATION_SET_IS_TRADES_LOADING](state, isLoading) {
    state.isTradesLoading = isLoading;
  },
  [MUTATION_SET_TRADES](state, trades) {
    state.trades = trades;
  },
  [MUTATION_SET_TRADES_TOTAL](state, tradesTotal) {
    state.tradesTotal = tradesTotal;
  },
  [MUTATION_ADD_TRADES](state, trades) {
    state.trades = [...state.trades, ...trades];
  },
  [MUTATION_SET_TRADES_PAGES_COUNT](state, count) {
    state.tradesPagesCount = count;
  },
  [MUTATION_SET_ECN_PROMO](state, ecnPromo) {
    state.ecnPromo = ecnPromo;
  },
  [MUTATION_SET_IS_ECN_PROMO_LOADING](state, isEcnPromoLoading) {
    state.isEcnPromoLoading = isEcnPromoLoading;
  },
  [MUTATION_SET_IS_ENABLE_ECN_PROMO_LOADING](state, isEnableEcnPromoLoading) {
    state.isEnableEcnPromoLoading = isEnableEcnPromoLoading;
  },
  [MUTATION_SET_APPLICATION_TITLE](state, applicationTitle) {
    state.applicationTitle = applicationTitle;
  },
  [MUTATION_SET_ACTIVE_ACCOUNT](state, activeAccount) {
    state.activeAccount = activeAccount;
  },
  [MUTATION_SET_IS_OPEN_SUCCESS_VERIFICATION_MODAL](state, status) {
    state.isOpenSuccessVerificationModal = status;
  },
  [MUTATION_SET_MAIN_CHANNEL_SOCKET_MESSAGES](state, messages) {
    state.mainChannelSocketMessages = messages;
  },
  [MUTATION_SET_AGORA_USER_PROFILE](state, profile) {
    state.agoraUserProfile = profile;
  },
  [MUTATION_SET_AGORA_USER_PROFILE_LOADING](state, isLoading) {
    state.isAgoraUserProfileLoading = isLoading;
  },
  [MUTATION_SET_WITHDRAWAl_PROCESSING_DATA](state, data) {
    state.withdrawalProcessingData = data;
  },
  [MUTATION_SET_USER_IP](state, userIp) {
    state.ip = userIp;
  },
};
