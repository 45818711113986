import { API_URL_EXTERNAL } from '../../consts/api-names';
import { useHttpClient } from '../../http/index';
import { GetProfileResponse } from './types';

const { get } = useHttpClient({
  baseUrl: API_URL_EXTERNAL,
});

export const useProfileApi = () => {
  const getProfile = async () => {
    const { data } = await get<GetProfileResponse>('/v1/user/profile');
    return data;
  };

  return {
    getProfile,

  };
};
