import { AxiosError } from 'axios';
import { errorTracker, logger, uiNotifier, httpErrorParser } from '@fsd/shared/lib';

function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError)?.isAxiosError === true;
}

export const errorHandler = async (error: unknown): Promise<never> => {
  try {
    if (isAxiosError(error)) {
      const { message, statusCode } = httpErrorParser.parse(error);

      if (statusCode && [401, 403].includes(statusCode)) {
        uiNotifier.error('Session expired. Please log in.');
      } else {
        uiNotifier.error(message || 'Something went wrong.');
      }

      logger.logError(error, 'API Error');
      errorTracker.track(error);
    } else if (error instanceof Error) {
      uiNotifier.error(error.message || 'Unexpected error occurred.');
      logger.logError(error, 'General Error');
    } else {
      uiNotifier.error('An unknown error occurred.');
      logger.logError(new Error('Unknown error type'), 'Unknown Error');
    }
  } catch (parsingError) {
    logger.logError(parsingError, 'Error handling failure');
  }

  return Promise.reject(error);
};
