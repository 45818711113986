import { DDateLocalized } from '@amarkets/atlas/esm/lib/d-date-localized.js';

export class WithdrawalProcessingData {
  constructor({
    payload: {
      withdrawal: {
        id,
        created_at,
      },
    },
  }) {
    Object.assign(this, {
      id,
      createdAt: new DDateLocalized(created_at).format('DD.MM.YYYY HH:mm'),
    });
  }
}
