import { SeverityLevel } from '@sentry/vue';

declare global {
  interface Window {
    sentry?: {
      captureException: (error: unknown, level: SeverityLevel) => void;
    };
  }
}

export const track = (error: unknown, level: SeverityLevel = 'error') => {
  if (window.sentry?.captureException) {
    window.sentry.captureException(error, level);
  } else {
    console.error('[Tracking Error]:', error);
  }
};
